var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"区域名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'areaName',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'areaName',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("负责人")]),_c('div',{staticClass:"left",staticStyle:{"margin-top":"4px"}},[_c('a-button',{staticStyle:{"margin-right":"4px"},attrs:{"block":""},on:{"click":_vm.add}},[_c('div',{staticClass:"left"},[(_vm.areaCharger.name)?_c('div',[_vm._v(_vm._s(_vm.areaCharger.name))]):_c('div',[_vm._v("请选择")])])]),_c('a-button',{attrs:{"icon":"delete","disabled":!_vm.areaCharger.name},on:{"click":_vm.deleteAreaCharger}})],1)])],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"上级区域"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'pid',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'pid',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],attrs:{"placeholder":"","changeOnSelect":"","options":_vm.tree,"fieldNames":{
  label: 'areaName',
  value: 'key',
  children: 'children',
}}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }